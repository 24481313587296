import React from 'react'
import { graphql } from 'gatsby'
import Modal from 'react-modal'
import { Seo } from '../components/base'
import { LunchForm } from '../components/forms'
import { SinglePage } from '../templates/SinglePage'

Modal.setAppElement('#___gatsby')

const NewsletterLunchPage = ({ data, pageContext }) => {
  const { image, imageMobile, headline, seo, subline, newsletterForm } =
    data.allSanityLunchNewsletterPage.nodes[0]

  const layoutBackgroundHeaderComponent = <h1 className="mb-2">{subline}</h1>
  const languages = { pageContext }

  return (
    <SinglePage
      title={headline}
      heroImage={image}
      heroImageMobile={imageMobile}
      heroTitle
      lunchNewsletter
      translations={languages}>
      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.ogImage?.asset?.url}
      />
      <div className="md:flex justify-center mt-12 container">
        <div className="mb-32 text-center break-words ">
          {layoutBackgroundHeaderComponent}
        </div>
      </div>
      <LunchForm formData={newsletterForm} markets={data.markets.nodes} />
    </SinglePage>
  )
}

export default NewsletterLunchPage

export const lunch = graphql`
  query ($language: String!) {
    markets: allSanityMarket(filter: { status: { in: ["9"] } }) {
      nodes {
        _id
        marketId
        countryCode
        name
        address {
          city
          street
          zip
          additionalAddress
        }
      }
    }
    allSanityLunchNewsletterPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        i18n_lang
        seo {
          ...SeoQuery
        }
        headline
        subline
        image {
          ...ImageObjectQuery
        }
        imageMobile {
          ...ImageQuery
        }
        newsletterForm {
          emailAddress {
            name
            placeholder
            required
            type
            initialValue
          }
          privacy {
            name
            _rawLabel
            required
            initialValue
          }
        }
      }
    }
  }
`
